<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="farmworkType" @change="loadFarmworks(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="30">浇水</el-radio-button>
                <el-radio-button :label="31">施肥</el-radio-button>
                <el-radio-button :label="32">水肥</el-radio-button>
                <el-radio-button :label="33">打药</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadFarmworks(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showFarmworkDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="farmworks.data" class="mt-2">
                <el-table-column prop="farmworkTypeName" label="农事类型" sortable width="100" />
                <el-table-column prop="reminderUsers" label="土地/种植户">
                    <template #default="scope">
                        <div>
                            <span class="text-success">[{{scope.row.area}} 亩]</span>
                            {{scope.row.landName}}
                        </div>
                        <div class="text-info">{{scope.row.farmerName}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="summary" label="概述" />
                <el-table-column prop="reminderUsers" label="管理人员/时间" width="200">
                    <template #default="scope">
                        <div>{{scope.row.worker}}</div>
                        <div class="text-info">
                            {{$moment.sd(scope.row.beginTime)}}-{{$moment.sd(scope.row.endTime)}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showFarmworkDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteFarmwork(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="farmworks.totalRecords"
                :current-page="farmworksPagination.currentPage" :page-size="farmworksPagination.pageSize" class="mt-3"
                @current-change="farmworksPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="farmworkDialogVisible" title="管理" :close-on-click-modal="false" width="800px">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="土地" class="form-item-required">
                            <el-input :value="farmwork.landName" readonly placeholder="土地">
                                <template #append v-if="farmwork.id==undefined">
                                    <el-button @click="selectPlantContractLandDialogVisible=true">选择</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="亩数" class="form-item-required">
                            <el-input-number v-model="farmwork.area" :min="0" controls-position="right" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="农事类型" class="form-item-required">
                            <el-select v-model="farmwork.farmworkType" placeholder="农事类型">
                                <el-option
                                    v-for="farmworkType2 in $store.state.dataDefinitions.farmworkTypes.filter(p=>p.key>=30&&p.key<40)"
                                    :key="farmworkType2.key" :value="farmworkType2.key" :label="farmworkType2.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="概述" class="form-item-required">
                    <el-input v-model="farmwork.summary" placeholder="概述" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="管理人员" class="form-item-required">
                            <el-input v-model="farmwork.worker" placeholder="管理人员" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="开始时间" class="form-item-required">
                            <el-date-picker v-model="farmwork.beginTime" placeholder="开始时间" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="结束时间">
                            <el-date-picker v-model="farmwork.endTime" placeholder="结束时间" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="mt-2">
                    <div>附件</div>
                    <div class="mt-2">
                        <picture-uploader v-model="farmwork.attachments" />
                    </div>
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveFarmwork">确定</el-button>
                <el-button @click="farmworkDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-plant-contract-land-dialog v-model="selectPlantContractLandDialogVisible"
            @select="selectPlantContractLand" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                farmworkType: null,
                key: null,
                farmworks: {},
                farmworksPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                farmwork: {},
                farmworkDialogVisible: false,
                selectPlantContractLandDialogVisible: false,
            };
        },
        methods: {
            async loadFarmworks(resetPage = false) {
                if (resetPage) {
                    this.farmworksPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Farmwork/GetFarmworks', {
                    params: {
                        companyId: this.$store.state.companyId,
                        type: 3,
                        farmworkType: this.farmworkType,
                        key: this.key,
                        pageIndex: this.farmworksPagination.currentPage - 1,
                        pageSize: this.farmworksPagination.pageSize,
                    }
                });
                this.farmworks = response.data;
            },
            farmworksPaginationCurrentChange(page) {
                this.farmworksPagination.currentPage = page;
                this.loadFarmworks();
            },
            showFarmworkDialog(farmwork) {
                if (farmwork) {
                    this.farmwork = JSON.parse(JSON.stringify(farmwork));
                }
                else {
                    this.farmwork = { farmworkType: this.farmworkType, area: 0, beginTime: new Date(), endTime: new Date() };
                }
                this.farmworkDialogVisible = true;
            },
            selectPlantContractLand(plantContractLand) {
                this.farmwork.plantContractLandId = plantContractLand.id;
                this.farmwork.landId = plantContractLand.landId;
                this.farmwork.landName = plantContractLand.landName;
                this.farmwork.area = plantContractLand.landArea;
            },
            async saveFarmwork() {
                if (this.farmwork.id == undefined) {
                    await this.$axios.post('/api/Farmwork/AddFarmwork', {
                        plantContractLandId: this.farmwork.plantContractLandId,
                        area: this.farmwork.area,
                        farmworkType: this.farmwork.farmworkType,
                        summary: this.farmwork.summary,
                        worker: this.farmwork.worker,
                        beginTime: this.$moment.sdt(this.farmwork.beginTime),
                        endTime: this.$moment.sdt(this.farmwork.endTime),
                        attachments: this.farmwork.attachments,
                    });
                    this.farmworkDialogVisible = false;
                    this.loadFarmworks();
                }
                else {
                    await this.$axios.post('/api/Farmwork/UpdateFarmwork', {
                        id: this.farmwork.id,
                        landId: this.farmwork.landId,
                        area: this.farmwork.area,
                        farmworkType: this.farmwork.farmworkType,
                        summary: this.farmwork.summary,
                        worker: this.farmwork.worker,
                        beginTime: this.$moment.sdt(this.farmwork.beginTime),
                        endTime: this.$moment.sdt(this.farmwork.endTime),
                        attachments: this.farmwork.attachments,
                    });
                    this.farmworkDialogVisible = false;
                    this.loadFarmworks();
                }
            },
            async deleteFarmwork(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Farmwork/DeleteFarmwork', { id: id });
                this.loadFarmworks();
            },
        },
        created() {
            this.loadFarmworks();
        },
    };
</script>